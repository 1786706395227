import LazyLoad from 'vanilla-lazyload';

const logEvent = (eventName, element) => {
	console.log(
		Date.now(),
		eventName,
		element.getAttribute('data-src'),
		element.getAttribute('src')
	);
};

const lazyLoadOptions = {
	elements_selector: '.lazy'
};

const lazyLoadOptionsSlider = {
	elements_selector: '.lazy',
	container: document.getElementById('slider-actus'),
	load_delay: 300,

	callback_enter: element => {
		logEvent('ENTERED', element);
	},
	callback_load: element => {
		logEvent('LOADED', element);
	},
	callback_set: element => {
		logEvent('SET', element);
	},
	callback_error: element => {
		logEvent('ERROR', element);
	}
};

const createLazyLoadInstance = () => {
	return new LazyLoad(lazyLoadOptions);
};
const createLazyLoadSlider = () => {
	return new LazyLoad(lazyLoadOptionsSlider);
};

export default () => {
	document.addEventListener('DOMContentLoaded', createLazyLoadInstance);
	if (document.body.className.match('home')) {
		document.addEventListener('DOMContentLoaded', createLazyLoadSlider);
	}
};
