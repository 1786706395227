// Styles SCSS
import '../sass/home.scss';

// LazyLoad
import lazyLoadInit from './lazyload-init';
lazyLoadInit();

import 'slick-carousel';

// import 'jquery.marquee';

// pushbar
import Pushbar from './utils/pushbar';

$(document).ready(() => {
	$('.actualites__slider').slick({
		dots: true,
		infinite: true,
		arrows: true,
		autoplay: false,
		autoplaySpeed: 2000,
		appendDots: '.actualites__dots',
		dotsClass: 'dots',
		prevArrow: $('.actualites__arrows--left'),
		nextArrow: $('.actualites__arrows--right')
	});
	$('.slider-bandeau').slick({
		dots: true,
		infinite: true,
		arrows: true,
		autoplay: true,
		autoplaySpeed: 5000,
		prevArrow: $('.bandeau__arrows--left'),
		nextArrow: $('.bandeau__arrows--right')
	});

	new Pushbar({
		blur: true,
		overlay: true
	});

	document.getElementById('toggle-search').onclick = function() {
		document
			.getElementById('searchform-responsive')
			.classList.toggle('visible');
	};

	$(window).scroll(function() {
		if ($(this).scrollTop()) {
			$('#toTop').fadeIn();
		} else {
			$('#toTop').fadeOut();
		}
	});

	$('#toTop').click(function() {
		//1 second of animation time
		//html works for FFX but not Chrome
		//body works for Chrome but not FFX
		//This strange selector seems to work universally
		$('html, body').animate({ scrollTop: 0 }, 1000);
	});
});
